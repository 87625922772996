import React from "react";
import Link from "../misc/link";

const IntegrationAnalysisToolCalloutSection = ({ integration, tool }) => {
    return (
        <section className="st-section both-integrations">
            <div className="st-callout">
                <div className="callout-content">
                    <div className="integration-combo-logos">
                        <div className="logo-box">
                            <img src={integration.cropped_logo.file.url} alt={integration.name} />
                        </div>
                        <div className="logo-box">
                            <img src={tool.logo.file.url} alt={tool.name} />
                        </div>
                    </div>
                    <h2 className="st-heading--2">Two powerful tools, together</h2>
                    <p className="st-subheading">
                        Integrate {integration.name} and {tool.name} with your data warehouse to turn your data into
                        actionable insights.
                    </p>
                    <button onclick="window.location.href = '#faster-decisions';" className="st-button">
                        See how we do it &nbsp;↓
                    </button>
                </div>
                <div className="callout-detail-list">
                    <div className="integration-item">
                        <h3 className="st-heading--4">About {integration.name}</h3>
                        {integration.integration_category.name === "Databases" ? (
                            <p style={{ marginBottom: "0px!important" }}>{integration.name} is a popular database.</p>
                        ) : (
                            <p style={{ marginBottom: "0px!important" }}>
                                {integration.name} {integration.short_description}.
                            </p>
                        )}
                        {integration.documentation_link ? (
                            <>
                                <p>
                                    Stitch offers detailed documentation on how to replicate all your {integration.name}
                                    data to your central warehouse today.
                                </p>
                                <p>
                                    <Link
                                        to={`https://www.stitchdata.com/docs${integration.documentation_link}`}
                                        className={`st-button-plain--alt`}
                                    >
                                        Stitch {integration.name} Documentation<span className="arrow"></span>
                                    </Link>
                                </p>
                            </>
                        ) : (
                            <p>
                                {integration.name} {integration.short_description}.
                            </p>
                        )}
                    </div>
                    <div className="integration-item">
                        <h3 className="st-heading--4">About {tool.name}</h3>
                        <p>{tool.description.description}</p>
                        <Link to={`${tool.url}`} className={`st-button-plain--alt`}>
                            Learn more about {tool.name} <span className="arrow"></span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntegrationAnalysisToolCalloutSection;
