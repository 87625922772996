import React from "react";
import { graphql } from "gatsby";
import SectionSeparator from "../components/layout/section-separator";
import TestimonialSection from "../components/layout/testimonial-section";
import DestinationsBoxSection from "../components/destinations/destinations-box-section";
import IntegrationFilterSection from "../components/integrations/integrations-filter-section";
import IntegrationAnalysisToolFasterDecisionsSection from "../components/integrations/integration-analysis-tool-faster-decisions-section";
import IntegrationAnalysisToolCalloutSection from "../components/integrations/integration-analysis-tool-callout-section";
import IntegrationWhyWarehouseCalloutSection from "../components/integrations/integration-why-warehouse-callout-section";
import IntegrationEndpointsGrid from "../components/integrations/integration-endpoints-grid";

import Layout from "../components/layout/layout";
import Seo from "../components/misc/Seo/seo";

const IntegrationTool = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const integration = data.integration;
    const tool = data.tool;
    const bodyClasses = `${pageContext.type} integrations integrations_${integration.url} integrations_${integration.url}_${tool.slug} q2`;

    let pageHeading = `Analyze your ${integration.name} data with ${tool.name}`;
    let pageSubHeading = `Stitch is a no-maintenance pipeline for consolidating all your data (including ${integration.name}) to modern analytics warehouses and storage platforms, powering rapid reporting in ${tool.name}.`;

    if (integration.status === "Coming Soon") {
        pageHeading = `Coming Soon: Analyze your ${integration.name} data with ${tool.name}`;
        pageSubHeading = `The Stitch ${integration.name} integration is currently in development. Enter your information below, and we’ll notify you when it's ready.`;
    }

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            headerItems={[integration, tool]}
            pageHeading={pageHeading}
            pageSubHeading={pageSubHeading}
        >
            <Seo
                type={"Page"}
                title={`Analyze your ${integration.name} data with ${tool.name} in minutes`}
                description={`Analyze your ${integration.name} data with ${tool.name} in minutes without the headache of writing and maintaining ETL scripts.`}
                slug={`/integrations/${integration.url}/${tool.slug}`}
                bodyClass={bodyClasses}
            />
            {integration.status !== "Coming Soon" && (
                <>
                    <SectionSeparator />
                    <IntegrationAnalysisToolCalloutSection integration={integration} tool={tool} />
                    <IntegrationAnalysisToolFasterDecisionsSection integration={integration} tool={tool} />
                    <SectionSeparator />
                    <IntegrationWhyWarehouseCalloutSection />
                    <DestinationsBoxSection
                        heading={`${integration.name} to your data warehouse in minutes`}
                        paragraph={`Stitch delivers your data to storage platforms purpose-built for fast data analysis.`}
                        note={true}
                    />
                    {integration.endpoints && <IntegrationEndpointsGrid integration={integration} />}
                </>
            )}
            {integration.customer ? (
                <TestimonialSection
                    quote={integration.customer[0].quote.quote}
                    author={integration.customer[0].quote_name}
                    position={`${integration.customer[0].quote_title}, ${integration.customer[0].company_name}`}
                />
            ) : (
                <TestimonialSection
                    quote={`Stitch saves us a ton of time. Having a standard process to put the data in the warehouse and then feeding that data to a dashboard gives us more time to dig and uncover the story the data is telling us.`}
                    author={`Kristen Allcorn`}
                    position={`Data Analyst`}
                    companyName={`NextAfter`}
                    companyUrl={"https://www.stitchdata.com/customers/nextafter/"}
                />
            )}
            <SectionSeparator />
            <IntegrationFilterSection
                heading={`Connect to your ecosystem of data sources`}
                paragraph={`Stitch integrates with leading databases and SaaS products. No API maintenance, ever, while you maintain full control over replication behavior.`}
            />
        </Layout>
    );
};

export const query = graphql`
    query($integrationSlug: String!, $toolSlug: String!) {
        integration: contentfulIntegration(url: { eq: $integrationSlug }) {
            contentful_id
            name
            url
            status
            microversename
            premium_integration
            short_description
            integration_category {
                name
            }
            cropped_logo {
                file {
                    url
                }
            }
            square_logo {
                file {
                    url
                }
            }
            documentation_link
            gitHubRepository
            endpoints {
                integration_name
                schema_name
                description {
                    description
                }
            }
            generate_microverse_pages
            customer {
                company_name
                quote_name
                quote_title
                quote {
                    quote
                }
            }
        }
        tool: contentfulAnalysisTool(slug: { eq: $toolSlug }) {
            contentful_id
            name
            slug
            url
            tutorial
            description {
                description
            }
            logo {
                file {
                    url
                }
            }
        }
    }
`;

export default IntegrationTool;
