import React from "react";
import Link from "../misc/link";
import CheckMark from "../../assets/svg/icon-check-plain.svg";

const IntegrationWhyWarehouseCalloutSection = () => {
    return (
        <section className="st-section" id="why-warehouse">
            <div className="st-callout features">
                <div className="callout-content">
                    <h2 className="st-heading--2">
                        The power of a modern<span className="st-line-break"></span> analytics stack
                    </h2>
                    <p className="st-subheading">
                        Years ago, building a data infrastructure meant maintaining custom ETL scripts and loading data
                        to expensive, on premises storage. <strong>Skip that.</strong> Stitch helps today’s most
                        successful organizations leverage the power of the cloud to optimize their analytics.
                    </p>
                    <Link to={`/resources/data-warehouse/`}>
                        <div className="resource-cta">
                            <h4>FREE RESOURCE</h4>
                            <span className="link">Data Warehouse: A Foundation for Business Intelligence&nbsp;→</span>
                            <p>
                                See why <strong>82% of businesses</strong> are prioritizing new technologies for
                                analytics and business intelligence.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="callout-detail-list">
                    <dl>
                        <dt className="st-heading--4">
                            <CheckMark />
                            Simple setup and maintenance
                        </dt>
                        <dd>
                            Today's cloud warehouses can be provisioned rapidly, and require little ongoing management.
                        </dd>

                        <dt className="st-heading--4">
                            <CheckMark />
                            Nearly infinite computing power
                        </dt>
                        <dd>
                            With modern data warehouses it’s easy to transform your data as you analyze it, without
                            bogging down your source systems or relying on slower, pre-load data transformations.
                        </dd>

                        <dt className="st-heading--4">
                            <CheckMark />
                            Cost effective
                        </dt>
                        <dd>
                            Scalable storage and on-demand compute power lowers the cost of your analytics
                            infrastructure&mdash;plus, there's no need to support in-house hardware and software.
                        </dd>
                    </dl>
                </div>
            </div>
        </section>
    );
};

export default IntegrationWhyWarehouseCalloutSection;
