import React from "react";
import Link from "../misc/link";
import Icon from "../../assets/images/icons/icon-integrations-table.svg";

const IntegrationEndpointsGrid = ({ integration }) => {
    const EndPointItem = ({ endpoint }) => {
        return (
            <div className="sample">
                <div>
                    <h3 className="st-heading--4">{endpoint.integration_name}</h3>
                    <p>{endpoint.description.description}</p>
                </div>
                <div className="table-description">
                    <span className="st-icon">
                        <img src={Icon} alt="" />
                    </span>
                    <span className="code-sample">
                        <code>
                            Table name:
                            <strong className="highlight">{endpoint.schema_name}</strong>
                        </code>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <section className="st-section database" id="endpoints">
            <div className="st-feature--thin">
                <h2 className="st-heading--2">Expected {integration.name} data</h2>
                <p className="st-subheading">
                    Here’s a sample of the raw {integration.name} data that Stitch will replicate to your analytics
                    warehouse:
                </p>
                {integration.endpoints.map((endpoint) => (
                    <EndPointItem endpoint={endpoint} />
                ))}

                <p>
                    <Link
                        to={`https://www.stitchdata.com/docs${integration.documentation_link}`}
                        className={`st-button-cta`}
                    >
                        View all tables<span className="arrow"></span>
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default IntegrationEndpointsGrid;
