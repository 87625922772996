import React from "react";
import Link from "../misc/link";
import { internalLinksMap, internalLinksMapTwo } from "./internal-links-map";
import AssetDataConsolidation from "../../assets/svg/asset-data-consolidation.svg";

const IntegrationAnalysisToolFasterDecisionsSection = ({ integration, tool }) => {
    const internalLink = internalLinksMap[integration.url] || null;
    const internalLinkTwo = internalLinksMapTwo[tool.slug] || null;

    let microverseUrl = "";
    microverseUrl = internalLinkTwo || `/analysis-tools/${tool.slug}`;

    return (
        <section className="st-section" id="faster-decisions">
            <div className="st-callout">
                <div className="callout-content">
                    <h2
                        className="st-heading--2"
                        dangerouslySetInnerHTML={{
                            __html: `Faster decisions with data. <span className="st-line-break"></span>For your entire organization.`,
                        }}
                    ></h2>
                    <p className="st-subheading">
                        From {internalLink ? <Link to={internalLink}>{integration.name}</Link> : integration.name} to{" "}
                        {integration.name === "Google Analytics" ? `Salesforce,` : `Google Analytics,`} the tools that
                        power your operations create incredibly valuable data&mdash;but it's locked up in silos. With
                        Stitch as your data pipeline, you can break down those barriers in minutes.
                    </p>
                    <h4>Consolidate</h4>
                    <p>
                        {["Looker", "Power BI", "Tableau", "Google Data Studio"].includes(tool.name) &&
                            integration.generate_microverse_pages && (
                                <>
                                    Don't waste time{" "}
                                    <Link
                                        to={`${microverseUrl}`}
                                        className={`manual-instructions manual-instructions-b`}
                                    >
                                        extracting data from {integration.name} and sending it to {tool.name} manually
                                    </Link>
                                </>
                            )}{" "}
                        Stitch easily connects to your data wherever it lives and automatically copies it to a central
                        data warehouse: a single source of truth that holds insights from every corner of your
                        organization.
                    </p>
                    <h4>Analyze</h4>
                    <p>
                        From there, it’s easy to connect your warehouse to{" "}
                        {internalLinkTwo ? <Link to={internalLinkTwo}>{tool.name}</Link> : tool.name}.
                    </p>
                    {tool.tutorial && (
                        <Link to={tool.tutorial} className={`st-button-cta`} id={`blog-tutorial`}>
                            See the {tool.name} Tutorial<span className="arrow"></span>
                        </Link>
                    )}
                </div>
                <div className="callout-decor">
                    <AssetDataConsolidation />
                    {!["Google Analytics", "Zendesk Support", "PostgreSQL", "Intercom", "Segment"].includes(
                        integration.name
                    ) && (
                        <div className="source">
                            <img src={integration.square_logo.file.url} alt={integration.name} />
                        </div>
                    )}
                    <div className="tool">
                        <img src={tool.logo.file.url} alt={tool.name} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntegrationAnalysisToolFasterDecisionsSection;
